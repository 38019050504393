<template>
  <div class="fill-height">
    <v-progress-linear
      v-if="isLoading || isLoadingObservations || isLoadingLastObservation || isLoadingLastAlert"
      indeterminate
      color="primary"
      absolute
    />
    <template v-else>
      <v-row v-if="items.length === 0" no-gutters align="center" justify="center" class="fill-height">
        <div class="text-center">
          <v-icon size="150" color="grey lighten-2">mdi-chart-line</v-icon>
          <div class="headline">
            {{ $t('message.no_observed_properties') }}
          </div>
          <div class="body-1">
            {{ $t('message.no_observed_properties_info') }}
          </div>
        </div>
      </v-row>
      <div v-else class="pa-4">
        <v-data-table
          v-if="tableMode"
          :headers="headers"
          :items="items"
          :items-per-page.sync="options.itemsPerPage"
          :options.sync="options"
          :server-items-length="totalItems"
          item-key="@id"
          style="width: 100%;"
          class="row-pointer transparent"
          @click:row="$router.push({ name: 'observedPropertyShow', params: { id: $iriToId($event['@id']) } })"
        >
          <template slot="item.lastObservation" slot-scope="{ item }">
            <template v-if="'undefined' === typeof item.lastObservation">
              <v-progress-circular indeterminate size="18" width="2" color="primary"></v-progress-circular>
            </template>
            <template v-else-if="null !== item.lastObservation">
              {{ item.lastObservation.result | humanizeResult(item.type) }}
              {{ item.units }}

              <span class="grey--text mx-1">|</span>

              {{ formatDistanceToNowStrict(new Date(item.lastObservation.observedAt), formatDistanceOptions) }}
            </template>
          </template>
        </v-data-table>
        <template v-else>
          <v-row>
            <v-col v-for="item in items" :key="item['@id']" cols="12" sm="6" md="4" lg="3" xl="3">
              <v-hover v-slot="{ hover }">
                <v-card
                  :to="{ name: 'observedPropertyShow', params: { id: $iriToId(item['@id']) } }"
                  outlined
                  :elevation="hover ? 10 : 0"
                  :style="{ 'border-color': item.lastAlert && 'recovery' !== item.lastAlert.type ? '#F44336' : null }"
                >
                  <v-card-text>
                    <div class="body-2">{{ item.name }}</div>
                    <template
                      v-if="
                        'undefined' === typeof item.lastObservation ||
                          'undefined' === typeof item.lastHourObservationsDesc
                      "
                    >
                      <v-progress-circular indeterminate size="18" width="2" color="primary"></v-progress-circular>
                    </template>
                    <template v-else>
                      <template v-if="null !== item.lastObservation">
                        <div class="title" :class="{ 'red--text': item.lastAlert && 'recovery' !== item.lastAlert.type }">
                          {{ item.lastObservation.result | humanizeResult(item.type) }}
                          {{ item.units }}
                          <v-icon v-if="item.lastAlert && 'over-max' === item.lastAlert.type" size="22" color="red" style="margin-top: -4px">
                            mdi-arrow-up
                          </v-icon>
                          <v-icon v-if="item.lastAlert && 'under-min' === item.lastAlert.type" size="22" color="red" style="margin-top: -4px">
                            mdi-arrow-down
                          </v-icon>
                        </div>

                        <div class="grey--text caption">
                          {{
                            formatDistanceToNowStrict(new Date(item.lastObservation.observedAt), formatDistanceOptions)
                          }}
                        </div>
                      </template>

                      <v-row
                        v-if="!item.lastObservation && !item.lastHourObservationsDesc"
                        align="center"
                        justify="center"
                        class="text-center"
                        style="min-height: 184px"
                      >
                        <div>
                          <v-icon size="80" color="grey lighten-2">mdi-chart-line</v-icon>
                          <div class="body-1 grey--text">
                            {{ $t('message.no_observations') }}
                          </div>
                        </div>
                      </v-row>

                      <v-row
                        v-if="item.lastObservation && !item.lastHourObservationsDesc"
                        align="center"
                        justify="center"
                        class="text-center"
                        style="min-height: 131px"
                      >
                        <div>
                          <v-icon size="80" color="grey lighten-2">mdi-chart-line</v-icon>
                          <div class="body-1 grey--text">
                            {{ $t('message.no_observations_in_the_last_hour') }}
                          </div>
                        </div>
                      </v-row>
                    </template>
                  </v-card-text>
                  <template v-if="item.lastHourObservationsAsc">
                    <ObservationSparklineLineChart
                      v-if="'line' === item.chartType"
                      :observed-property="item"
                      :observations="item.lastHourObservationsAsc"
                    />
                    <ObservationSparklineBarChart
                      v-else-if="'bar' === item.chartType"
                      :observed-property="item"
                      :observations="item.lastHourObservationsAsc"
                    />
                    <ObservationPieChart
                      v-else-if="'pie' === item.chartType"
                      :observed-property="item"
                      :observations="item.lastHourObservationsAsc"
                      :sparkline="true"
                    />
                    <ObservationStatusChart
                      v-else-if="'status' === item.chartType"
                      :observed-property="item"
                      :observations="item.lastHourObservationsAsc"
                      :sparkline="true"
                    />

                    <div v-else style="min-height: 127px">
                      <v-divider />
                      <v-list dense class="pa-0">
                        <v-list-item
                          v-for="observation in item.lastHourObservationsDesc.slice(0, 3)"
                          :key="observation['@id']"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ observation.result | humanizeResult(item.type) }}
                              {{ item.units }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text>
                              <template v-if="isToday(new Date(observation.observedAt))">
                                {{ utcformat(new Date(observation.observedAt), 'pp') }}
                              </template>
                              <template v-else>
                                {{ utcformat(new Date(observation.observedAt), 'Ppp') }}
                              </template>
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </div>
                  </template>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ListMixin from 'usail-vue-sdk/mixins/ListMixin'
import { formatDistanceToNowStrict, subMinutes, format, addMinutes, isToday } from 'date-fns'
import { es } from 'date-fns/locale'
import ObservationSparklineLineChart from '@/components/Observation/Chart/SparklineLine'
import ObservationSparklineBarChart from '@/components/Observation/Chart/SparklineBar'
import ObservationPieChart from '@/components/Observation/Chart/Pie'
import ObservationStatusChart from '@/components/Observation/Chart/Status'

export default {
  name: 'ObservedPropertyList',
  components: {
    ObservationStatusChart,
    ObservationPieChart,
    ObservationSparklineBarChart,
    ObservationSparklineLineChart
  },
  servicePrefix: 'Site',
  mixins: [ListMixin],

  props: {
    site: {
      type: Object,
      required: true
    },

    tableMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formatDistanceToNowStrict,
      es,
      format,
      addMinutes,
      isToday,
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Last observation', value: 'lastObservation', sortable: false, align: 'right' }
      ],
      filters: {
        site: this.site['@id']
      },
      items: [],
      isLoadingObservations: false,
      isLoadingLastObservation: false,
      isLoadingLastAlert: false
    }
  },

  computed: {
    ...mapGetters('observedProperty', {
      rawItems: 'list'
    }),
    ...mapFields('observedProperty', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    }),

    formatDistanceOptions() {
      let options = { addSuffix: true }

      if ('es' === this.$i18n.locale) {
        options.locale = this.es
      }

      return options
    }
  },

  watch: {
    rawItems(rawItems) {
      this.items = JSON.parse(JSON.stringify(rawItems))
      this.items.sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })
      this.fetchAll()
    }
  },

  methods: {
    ...mapActions('observedProperty', {
      getPage: 'fetchAll',
      deleteItem: 'del'
    }),

    utcformat(date, format) {
      return this.format(this.addMinutes(date, date.getTimezoneOffset()), format)
    },

    fetchAll() {
      this.fetchLastObservation()
      this.fetchLastHourObservations()
      this.fetchLastAlert()
    },

    fetchLastObservation() {
      this.items.forEach((item, index) => {
        this.isLoadingLastObservation = true
        this.$api
          .fetch(
            '/observations?observedProperty=' +
              item['@id'] +
              '&pagination=false&order[observedAt]=desc' +
              '&itemsPerPage=1'
          )
          .then(response => {
            return response.json()
          })
          .then(data => {
            if (data['hydra:member'].length === 0) {
              this.$set(this.items[index], 'lastObservation', null)
            } else {
              this.$set(this.items[index], 'lastObservation', data['hydra:member'][0])
            }
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log(e)
          })
          .finally(() => {
            this.isLoadingLastObservation = false
          })
      })
    },

    fetchLastHourObservations() {
      this.items.forEach((item, index) => {
        this.isLoadingObservations = true
        const now = new Date()
        this.$api
          .fetch(
            '/observations?observedProperty=' +
              item['@id'] +
              '&pagination=false&order[observedAt]=desc' +
              '&observedAt[after]=' +
              subMinutes(now, 60).toISOString() +
              '&observedAt[before]=' +
              now.toISOString()
          )
          .then(response => {
            return response.json()
          })
          .then(data => {
            if (data['hydra:member'].length === 0) {
              this.$set(this.items[index], 'lastHourObservationsDesc', null)
              this.$set(this.items[index], 'lastHourObservationsAsc', null)
            } else {
              this.$set(this.items[index], 'lastHourObservationsDesc', data['hydra:member'])

              let observations = JSON.parse(JSON.stringify(data['hydra:member']))
              observations.sort((a, b) => {
                return a.observedAt < b.observedAt ? -1 : 1
              })
              this.$set(this.items[index], 'lastHourObservationsAsc', observations)
            }
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log(e)
          })
          .finally(() => {
            this.isLoadingObservations = false
          })
      })
    },

    fetchLastAlert() {
      this.items.forEach((item, index) => {
        this.isLoadingLastAlert = true
        this.$api
          .fetch(
            '/alerts?causedBy.observedProperty=' +
              item['@id'] +
              '&pagination=false&order[raisedAt]=desc' +
              '&itemsPerPage=1'
          )
          .then(response => {
            return response.json()
          })
          .then(data => {
            if (data['hydra:member'].length === 0) {
              this.$set(this.items[index], 'lastAlert', null)
            } else {
              this.$set(this.items[index], 'lastAlert', data['hydra:member'][0])
            }
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log(e)
          })
          .finally(() => {
            this.isLoadingLastAlert = false
          })
      })
    }
  }
}
</script>
