<template>
  <apexchart v-if="observations.length > 0" height="125" type="area" :series="series" :options="options" />
</template>

<script>
import { format } from 'date-fns'
export default {
  name: 'ObservationSparklineLineChart',
  props: {
    observedProperty: {
      type: Object,
      required: true
    },

    observations: {
      type: Array,
      required: true
    }
  },

  computed: {
    options() {
      if (this.observations.length === 0) {
        return
      }

      const units = this.observedProperty.units

      let categories = []
      this.observations.forEach(observation => {
        categories.push(new Date(observation.observedAt).getTime())
      })

      return {
        chart: {
          sparkline: {
            enabled: true
          }
        },

        stroke: {
          curve: 'straight'
        },

        fill: {
          opacity: 0.3
        },

        xaxis: {
          type: 'datetime',
          categories: categories
        },

        tooltip: {
          x: {
            formatter: function(val, opts) {
              return format(val, 'Ppp')
            }
          },
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              return value + ' ' + (!units ? '' : units)
            }
          }
        }
      }
    },

    series() {
      if (this.observations.length === 0) {
        return
      }

      let data = []
      this.observations.forEach(observation => {
        data.push(Number(observation.result))
      })

      return [
        {
          name: this.$t('message.result'),
          data: data
        }
      ]
    }
  }
}
</script>
