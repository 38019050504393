<template>
  <div class="fill-height grey lighten-5">
    <AppToolbar />
    <template v-if="error">
      <PageNotFound v-if="'Not Found' === error" />
      <GenericError v-else />
    </template>
    <template v-else>
      <v-progress-linear v-if="isLoading" indeterminate color="primary" absolute />
      <div v-else-if="item" class="fill-height d-flex flex-column">
        <v-toolbar flat @click="$refs.list.fetchAll()">
          <v-toolbar-title>{{ item.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn-toggle v-model="tableToggle">
            <v-btn>
              <v-icon>mdi-view-grid</v-icon>
            </v-btn>
            <v-btn>
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>
        <v-divider></v-divider>
        <ObservedPropertyList v-if="item" ref="list" :site="item" :table-mode="Boolean(tableToggle)" />
      </div>
    </template>
  </div>
</template>

<script>
import AppToolbar from '@/components/AppToolbar'
import ObservedPropertyList from '@/components/ObservedProperty/List'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ShowMixin from 'usail-vue-sdk/mixins/ShowMixin'
import PageNotFound from 'usail-vue-sdk/components/error/PageNotFound'
import GenericError from 'usail-vue-sdk/components/error/GenericError'

export default {
  name: 'SiteShow',
  servicePrefix: 'Site',
  endpoint: 'sites',
  components: {
    ObservedPropertyList,
    AppToolbar,
    PageNotFound,
    GenericError
  },
  mixins: [ShowMixin],
  data() {
    return {
      tableToggle: 0
    }
  },
  computed: {
    ...mapFields('site', {
      item: 'retrieved',
      isLoading: 'isLoading',
      error: 'error'
    }),
    ...mapGetters('site', ['find'])
  },
  watch: {
    tableToggle(newValue) {
      localStorage.setItem('tableToggle', newValue)
    }
  },
  mounted() {
    let tableToggle = localStorage.getItem('tableToggle')
    if (tableToggle) {
      this.tableToggle = Number(tableToggle)
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.retrieve('/sites/' + to.params.id)
    next()
  },

  methods: {
    ...mapActions('site', {
      deleteItem: 'del',
      reset: 'resetShow',
      retrieve: 'load'
    })
  }
}
</script>
